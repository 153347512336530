.rating {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	width: 80px;
	height: 40px;
	background-color: #ffffff;
	box-shadow: 0 2px 4px 0 #0000003b;

	&__icon {
		width: 25px;
		height: 25px;
		fill: #f6d5a6;
	}
}
