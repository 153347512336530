.container {
	width: 100%;
	max-width: 1200px;
	padding: 0 20px;
}

@media (width <= 768px) {
	.container {
		max-width: 100%;
		padding: 0;
	}
}
