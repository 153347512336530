@import 'src/styles/variables';
@import '/src/styles/mixins';

.link {
	@include font-montserrat($fz: 16px, $fw: 500);
	position: relative;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 15px 20px;
	text-wrap: nowrap;
	background-color: transparent;
	border: 0;
	border-radius: 25px;
	cursor: pointer;
	transition: filter 0.3s ease;

	&:hover {
		filter: opacity(80%);
	}

	&--black {
		color: $color-text-accent;
		border: 1px solid $color-border-primary;
	}

	&--green {
		color: $color-text-secondary;
		background-color: #14362f;
	}

	&--lightGreen {
		color: #ffffff;
		background-color: #dbf0dd;
	}

	&--small {
		@include font-montserrat($fz: 14px, $fw: 500);
		padding: 10px 30px;
	}

	&--large {
		min-width: 172px;
		padding: 14px 35px;
	}
}

@media (width <= 480px) {
	.link {
		&--large {
			font-size: 18px;
		}
	}
}
