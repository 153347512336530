.close {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 58px;
	height: 58px;
	border: 0;
	border-radius: 50%;
	cursor: pointer;

	&--green {
		background-color: #14362f;
		stroke: #ffffff;
	}

	&--white {
		background-color: #ffffff;
		stroke: #000000;
	}

	&__icon {
		width: 40px;
		height: 40px;
	}
}

@media (width <= 480px) {
	.close {
		width: 40px;
		height: 40px;

		&__icon {
			width: 24px;
			height: 24px;
		}
	}
}
