.otherCars {
	display: flex;
	flex-direction: column;
	gap: 60px;

	&__title {
		padding: 0 20px;
		color: #383632;
		font-size: clamp(1.5rem, 1.21rem + 1.43vw, 2.5rem);
	}
}
