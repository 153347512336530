@import 'src/styles/mixins';

.dropdown {
	@include font-montserrat($fz: 14px, $fw: 500);
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	max-width: 270px;

	&__wrapper {
		position: relative;
	}

	&__button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 10px 20px;
		color: #1a1919;
		font-weight: inherit;
		background-color: #ffffff;
		border: 1px solid #231f20;
		border-radius: 2px;
		cursor: pointer;

		&--open {
			border-radius: 2px 2px 0 0;
		}
	}

	&__title {
		&--uppercase {
			text-transform: uppercase;
		}
	}

	&__icon {
		transition: all 0.5s;

		&--open {
			rotate: 180deg;
		}
	}

	&__content {
		position: absolute;
		z-index: 20;
		width: 100%;
		max-height: 0;
		overflow: hidden;
		overflow-y: auto;
		text-align: start;
		text-transform: uppercase;
		background-color: #ffffff;
		border: 1px solid #231f20;
		border-top: none;
		border-radius: 0 0 4px 4px;
		transition: max-height 0.3s ease;

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: #231f20;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #555555;
		}
	}

	&__option {
		padding: 10px 20px;
		border-top: 1px solid #231f20;
		cursor: pointer;
		transition: all 0.5s;

		&:hover {
			color: #ffffff;
			background-color: #1c4842;
		}

		&--active {
			color: #ffffff;
			background-color: #1c4842;
		}
	}
}

@media (width <= 960px) {
	.dropdown {
		max-width: 100%;
	}
}

@media (width <= 500px) {
	.dropdown {
		&__button {
			padding: 15px 20px;
		}
	}
}
