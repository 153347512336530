@import 'src/styles/mixins';

.carSpecs {
	display: flex;

	&__iconWrapper {
		display: flex;
		flex-basis: 25%;
		justify-content: center;
		align-items: center;
		padding: 20px;
		background-color: #14362f;
		border-radius: 8px 0 0 8px;
	}

	&__textWrapper {
		@include font-montserrat(
			$fz: clamp(0.88rem, 0.84rem + 0.18vw, 1rem),
			$fw: 600
		);
		display: flex;
		align-items: center;
		width: 100%;
		padding: 0 40px 0 20px;
		background-color: #ffffff;
		border-radius: 0 8px 8px 0;
	}
}
