@import 'src/styles/variables';
@import 'src/styles/mixins';

.tag__rating {
	@include font-montserrat($fz: 14px, $fw: 600);
	display: flex;
	justify-content: center;
	align-items: center;
	color: $color-text-secondary;
	border-radius: 0 0 5px 5px;

	&--bad {
		background-color: #c46c6c;
	}

	&--good {
		background-color: #8bb298;
	}
}
