@import 'src/styles/variables';

.modalConfirmation {
	&__wrapper {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background: rgba($color: #000000, $alpha: 80%);
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;
		padding: 100px;
		background-color: #ffffff;
		border-radius: 20px;
	}

	&__buttonClose {
		position: absolute;
		top: -70px;
		left: 100%;
	}

	&__iconWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 120px;
		height: 120px;
		background: $gradient-primary;
		border-radius: 50%;

		& svg {
			width: 70px;
			height: 70px;
		}
	}

	&__title {
		font-weight: 700;
		font-size: clamp(2rem, 1.68rem + 1.61vw, 3.13rem);
		text-align: center;
	}
}

@media (width <= 820px) {
	.modalConfirmation {
		&__buttonClose {
			left: 90%;
		}
	}
}

@media (width <= 768px) {
	.modalConfirmation {
		&__buttonClose {
			left: 80%;
		}

		&__content {
			padding: 50px;
		}
	}
}

@media (width <= 420px) {
	.modalConfirmation {
		&__content {
			border-radius: 0;
		}
	}
}

@media (width <= 480px) {
	.modalConfirmation {
		&__iconWrapper {
			width: 72px;
			height: 72px;

			& svg {
				width: 40px;
				height: 40px;
			}
		}

		&__buttonClose {
			top: -60px;
		}
	}
}
