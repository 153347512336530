@import 'src/styles/mixins';

.accordion {
	display: flex;
	flex-direction: column;

	&__head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 40px;
		border-bottom: 1px solid #e1e1e2;
	}

	&__title {
		@include font-montserrat($fz: clamp(1rem, 1rem + 0.2vw, 1.13rem), $fw: 600);
		color: #221e1f;
	}

	&__button {
		background-color: transparent;
		border: 0;
		cursor: pointer;
		stroke: #14362f;
	}

	&__content {
		@include font-hind($fz: 16px, $fw: 400);
		max-height: 0;
		overflow: hidden;
		color: #4f4f4f;
		border-bottom: 1px solid #e1e1e2;
		transition: max-height 0.3s ease;
	}

	&__answer {
		padding: 20px 300px 10px 40px;
	}
}

@media (width <= 768px) {
	.accordion {
		&__answer {
			padding: 20px 40px;
		}
	}
}
