@import 'src/styles/mixins';

.textarea {
	@include font-montserrat($fz: 14px, $fw: 600);
	width: 100%;
	height: 160px;
	padding: 25px;
	background-color: transparent;
	border: 1px solid #231f20;
	border-radius: 5px;
	resize: none;

	&::placeholder {
		color: #c0c0c0;
	}
}

@media (width <= 480px) {
	.textarea {
		height: 320px;
	}
}
