@import 'src/styles/mixins';

.ratingCar {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 50px;

	&__header {
		display: flex;
		flex-direction: column;
		gap: 30px;
		width: 100%;
	}

	&__title {
		color: #383632;
		font-size: clamp(1.66rem, 1.38rem + 1.25vw, 2.5rem);
	}

	&__ratingWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 30px;
		width: 100%;
		max-width: 430px;
	}

	&__stars {
		display: flex;
		align-items: flex-end;
	}

	&__dottedIcon {
		align-self: flex-end;
		width: 100%;
		stroke: #7c7c7c;
	}

	&__rating {
		@include font-montserrat(
			$fz: clamp(1.25rem, 1.11rem + 0.71vw, 1.75rem),
			$fw: 600
		);
		line-height: 1;
	}

	&__description {
		@include font-hind($fz: clamp(1rem, 0.93rem + 0.36vw, 1.25rem), $fw: 400);
		max-width: 430px;
		color: #4f4f4f;
		line-height: 1.3;
	}
}

@media (width <= 768px) {
	.ratingCar {
		padding: 0 20px;
	}
}

@media (width <= 480px) {
	.ratingCar {
		&__ratingWrapper {
			gap: 15px;
			max-width: unset;
		}
	}
}
