@import 'src/styles/mixins';

.reviewsFilters {
	width: 100%;
	background-color: #ffffff;
	border-radius: 5px 5px 0 0;

	&--open {
		border-radius: 5px;
	}

	&__head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		padding: 25px 40px 25px 80px;
	}

	&__title {
		@include font-montserrat($fz: 14px, $fw: 600);
		color: #383632;
	}

	&__button {
		@include font-montserrat($fz: 12px, $fw: 500);
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 15px;
		color: #b3b3b3;
		background: transparent;
		border: 0;
		cursor: pointer;
	}

	&__icon {
		stroke: #383632;
	}

	&__contentWrapper {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s ease;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding: 0 80px 40px;
	}

	&__column {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	&__subtitle {
		@include font-montserrat(
			$fz: clamp(0.88rem, 0.84rem + 0.18vw, 1rem),
			$fw: 500
		);
		color: #b3b3b3;
	}
}

.column {
	&__stars {
		padding-right: 80px;
		border-right: 1px solid #d0d0d0;
	}

	&__properties {
		padding-left: 90px;
	}
}

@media (width <= 900px) {
	.reviewsFilters {
		border-radius: 0;

		&__head {
			padding: 20px 20px 25px;
		}

		&__content {
			display: flex;
			flex-direction: column;
			padding: 0 20px 60px;
		}

		&__column {
			gap: 25px;
		}
	}

	.column {
		&__stars {
			padding-right: 0;
			padding-bottom: 50px;
			border-right: none;
			border-bottom: 1px solid #d0d0d0;
		}

		&__properties {
			padding-top: 30px;
			padding-left: 0;
		}
	}
}
