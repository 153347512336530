.star {
	&__icon {
		width: 30px;
		stroke: #979797;

		&--fill {
			fill: #f6d5a6;
			stroke: #f6d5a6;
		}
	}
}
