@import 'src/styles/mixins';
@import 'src/styles/variables';

.filters {
	width: 100%;

	&__head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 50px;
		background: $gradient-primary;
		border-radius: 5px 5px 0 0;
	}

	&__title {
		@include font-montserrat($fz: 14px, $fw: 600);
		color: $color-text-secondary;
	}

	&__button {
		background-color: transparent;
		border: 0;
		cursor: pointer;
		opacity: 1;
		stroke: #ffffff;
	}

	&__contentWrapper {
		max-height: 0;
		margin-bottom: 10px;
		overflow: hidden;
		transition: max-height 0.3s ease;
	}

	&__content {
		padding: 25px 50px 60px;
		background-color: $color-primary;
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}

	&__parameters {
		display: flex;
		gap: 20px;
	}

	&__range {
		max-width: 370px;
	}

	&__tags {
		display: flex;
		gap: 10px;
		min-height: 30px;
		margin-top: 15px;
	}

	&__buttons {
		display: flex;
		justify-content: flex-start;
		gap: 20px;
	}
}

@media (width <= 960px) {
	.filters {
		&__head {
			padding: 10px 45px 10px 25px;
		}

		&__content {
			padding: 40px 25px;
		}

		&__parameters {
			flex-direction: column;
			gap: 15px;
		}

		&__range {
			max-width: 100%;
		}

		&__buttons {
			justify-content: center;
			margin-top: 20px;
		}
	}
}

@media (width <= 768px) {
	.filters {
		&__head {
			border-radius: 0;
		}

		&__contentWrapper {
			margin-bottom: 45px;
		}

		&__tags {
			display: none;
		}
	}
}

@media (width <= 480px) {
	.filters {
		&__buttons {
			flex-direction: column-reverse;
			gap: 15px;
		}
	}
}
