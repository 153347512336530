@import 'src/styles/mixins';

.inputOwnershipDuration {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 370px;

	&__input {
		@include font-montserrat($fz: 16px, $fw: 500);
		padding: 10px 0;
		padding-left: 0;
		color: #000203;
		background-color: transparent;
		border: 0;
		border-bottom: 1px solid #d2d2d2;

		&:focus,
		&:not(:placeholder-shown) {
			outline: none;
		}

		&:focus + label,
		&:not(:placeholder-shown) + label {
			top: -14px;
		}
	}

	&__label {
		@include font-montserrat($fz: 14px, $fw: 600);
		position: absolute;
		top: 8px;
		color: #c0c0c0;
		transition: 0.2s ease all;
		pointer-events: none;
	}

	&__months {
		@include font-montserrat($fz: 16px, $fw: 500);
		position: absolute;
		top: 10px;
		right: calc(100% - 110px);
		pointer-events: none;
	}

	&__error {
		margin-top: 10px;
	}
}

@media (width <= 960px) {
	.inputOwnershipDuration {
		max-width: 100%;
	}
}
