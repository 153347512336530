.main {
	display: flex;
	flex-direction: column;
	gap: 120px;

	&__section {
		display: flex;
		justify-content: center;
		width: 100%;

		&--no-center {
			display: block;
		}
	}
}

.section {
	&__carProperty {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 40px;
	}
}

@media (width <= 768px) {
	.main {
		gap: 60px;
	}
}
