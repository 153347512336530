@import 'src/styles/variables';
@import 'src/styles/mixins';

.search {
	position: relative;
	background-color: transparent;

	&__icon {
		position: absolute;
		top: 5%;
	}

	&__input {
		@include font-montserrat($fz: 14px, $fw: 600);
		width: 100%;
		padding: 15px 55px;
		border: 0;
		outline: 0;

		&::placeholder {
			color: #c0c0c0;
		}
	}
}
