@import 'src/styles/variables';
@import '/src/styles/mixins';

.buttonLoading {
	@include font-montserrat($fz: 16px, $fw: 500);
	position: relative;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	width: 100%;
	padding: 15px 20px;
	text-wrap: nowrap;
	background-color: transparent;
	border: 0;
	border-radius: 25px;
	cursor: pointer;
	transition: transform 0.3s ease;

	&:hover {
		transform: scale(1.05);
	}

	&:disabled {
		filter: brightness(50%);
		pointer-events: none;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-top: -20px;
	}

	&__errorText {
		min-height: 20px;
		color: red;
		font-size: 16px;

		@include font-hind($fz: 14px, $fw: 400);
	}

	&--black {
		color: $color-text-accent;
		border: 1px solid $color-border-primary;
	}

	&--green {
		color: $color-text-secondary;
		background-color: #14362f;
	}

	&--lightGreen {
		color: #252525;
		background-color: #dbf0dd;
	}

	&--small {
		@include font-montserrat($fz: 14px, $fw: 500);
		padding: 10px 30px;
	}

	&--large {
		min-width: 172px;
		padding: 14px 35px;
	}
}

@media (width <= 480px) {
	.button {
		&--large {
			font-size: 18px;
		}
	}
}
