.customArrow {
	position: absolute;
	top: 50%;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 74px;
	height: 74px;
	background: #ffffff;
	border: 0;
	border-radius: 50%;
	cursor: pointer;
	transform: translateY(-50%);
	transition: all 0.3s;

	&:hover {
		box-shadow: 0 6px 24px -8px #dcc1b1;
	}

	&--prev {
		left: -84px;
	}

	&--next {
		right: -84px;
	}

	&__icon {
		width: 25px;
		height: 25px;
		stroke: #333333;

		&--prev {
			rotate: 90deg;
		}

		&--next {
			rotate: -90deg;
		}
	}
}
