@import 'src/styles/mixins';

.range {
	&__wrapper {
		@include font-montserrat($fz: 16px, $fw: 600);
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		gap: 5px;
		width: 100%;
	}

	&__label {
		flex-grow: 2;
		flex-basis: 60%;
		color: #252525;
	}

	&__sliderWrapper {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		width: 100%;
	}

	&__values {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 15px;
		padding: 0 10px;
		color: #c0c0c0;
	}

	&__slider {
		width: 100%;
		height: 5px;
		background: #d3d3d3;
		border-radius: 100px;
	}

	&__track {
		position: absolute;
		height: 5px;
	}

	&__thumb {
		top: -12px;
		width: 28px;
		height: 28px;
		background-color: #ffffff;
		border-radius: 50%;
		box-shadow: 0 2px 5px 0 #00000080;
		cursor: pointer;
	}

	&__rating {
		position: absolute;
		top: -70px;
	}
}

@media (width <= 1100px) {
	.range {
		&__wrapper {
			flex-wrap: wrap;
			gap: 50px;
		}
	}
}
