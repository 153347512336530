.arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	background-color: #14362f;
	border-radius: 50%;

	&__icon {
		width: 20px;
		height: 20px;
		stroke: #ffffff;
	}
}
