.slider {
	padding: 0 80px;

	&__slide {
		padding: 15px 30px;
	}

	&__logo {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 135px;
		height: 135px;
		background-color: #ffffff;
		border-radius: 50%;
		box-shadow: 0 5px 12px 0 #0000002d;
	}

	&__image {
		max-width: 100px;
		height: auto;
	}
}

@media (width <= 960px) {
	.slider {
		max-width: 100%;
		padding: 0;

		&__slide {
			padding: 15px 0;
		}

		&__logo {
			width: 64px;
			height: 64px;
		}

		&__image {
			max-width: 40px;
		}
	}
}
