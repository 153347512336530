@mixin font-hind($fz, $fw) {
	font-weight: $fw;
	font-size: $fz;
	font-family: 'Hind', sans-serif;
}

@mixin font-montserrat($fz, $fw) {
	font-weight: $fw;
	font-size: $fz;
	font-family: 'Montserrat', sans-serif;
}
