.sliderCardsCars {
	max-width: 100%;

	&--clip {
		clip-path: inset(0 -50% 0 0);
	}

	&__slide {
		padding: 20px 10px;
	}
}

@media (width >= 768px) {
	.sliderCardsCars {
		:global(.slick-list) {
			overflow: visible;
		}
	}
}
