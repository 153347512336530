.scoresCar {
	display: flex;
	gap: 40px;

	&__column {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
		max-width: 450px;
	}
}

@media (width <= 768px) {
	.scoresCar {
		flex-direction: column;
		padding: 0 20px;

		&__column {
			gap: 40px;
			max-width: 100%;
		}
	}
}
