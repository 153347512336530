@import 'src/styles/mixins';
@import 'src/styles/variables';

.models {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 25px;
	max-width: 100%;
	text-align: center;

	&__header {
		display: flex;
		flex-direction: column;
		gap: 25px;
		padding: 0 20px;
	}

	&__title {
		color: $color-text-accent;
		font-size: clamp(1.63rem, 1.4rem + 1.3vw, 2.5rem);
	}

	&__text {
		@include font-hind($fz: clamp(1.13rem, 1rem + 0.2vw, 1.25rem), $fw: 400);
		color: $color-text-primary;
	}

	&__cards {
		display: flex;
		flex-wrap: wrap;
		gap: 25px;
		width: 100%;
		margin-bottom: 30px;

		&--overflow-mobile {
			max-width: 870px;
		}
	}

	&__sliderWrapper {
		width: 100%;
		max-width: 100%;
	}

	&__buttons {
		max-width: 200px;
	}
}

@media (width <= 900px) {
	.models {
		&__cards {
			&--overflow-mobile {
				max-width: 570px;
			}
		}
	}
}

@media (width <= 600px) {
	.models {
		&__cards {
			&--overflow-mobile {
				flex-direction: column;
				align-items: center;
			}
		}
	}
}

@media (width <= 480px) {
	.models {
		&__buttons {
			min-width: 100%;
			padding: 0 25px;
		}
	}
}
