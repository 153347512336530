.preloader {
	display: block;
	width: 100%;
	height: 100%;

	&::after {
		display: block;
		width: 16px;
		height: 16px;
		border: 3px solid rgba($color: #ffffff, $alpha: 40%);
		border-bottom: 3px solid #ffffff;
		border-radius: 50%;
		animation: loader 2s linear infinite;
		content: '';
	}
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
