@import 'src/styles/mixins';

.ratingBand {
	@include font-montserrat($fz: 16px, $fw: 600);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	max-width: 450px;
	column-gap: 20px;

	&__property {
		flex-basis: 30%;
		min-width: 40%;
		color: #252525;
	}

	&__list {
		display: flex;
		align-items: center;
		gap: 1px;
	}

	&__icon {
		width: 25px;
		height: 5px;
		background-color: #dbdbdb;

		&--on {
			background-color: #14362f;
		}
	}

	&__rating {
		color: #000000;
	}
}

@media (width <= 768px) {
	.ratingBand {
		max-width: unset;
		font-size: 14px;
	}
}

@media (width <= 480px) {
	.ratingBand {
		column-gap: 0;

		&__property {
			flex-basis: 100%;
			min-width: unset;
		}
	}
}
