@import 'src/styles/variables';
@import 'src/styles/mixins';

.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -50px;
	color: #ffffff;
	background: $gradient-primary;

	&__questions {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding-top: 90px !important;
		padding-bottom: 90px !important;
	}

	&__title {
		font-size: clamp(1.3rem, 1.4rem + 1.3vw, 2.5rem);
	}

	&__subtitle {
		max-width: 500px;
		font-size: clamp(1rem, 0.9rem + 0.7vw, 1.5rem);
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__emailInput {
		@include font-montserrat($fz: 14px, $fw: 600);
		width: 100%;
		max-width: 370px;
		padding: 15px 20px;
		color: #ffffff;
		background-color: transparent;
		border: 2px solid #ffffff;
		border-radius: 2px;

		&::placeholder {
			color: #c0c0c0;
		}
	}

	&__buttons {
		max-width: 140px;
	}

	&__circle {
		position: absolute;
		top: 20%;
		left: calc(100% - 42%);
		width: 630px;
		height: 630px;
		background: linear-gradient(90deg, #8bb298 14.16%, #051d1e 97.04%);
		border-radius: 50%;
	}

	&__contacts {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 65px;
		width: 100%;
		padding-top: 50px;
		padding-bottom: 50px;
		background-image: url('../../assets/speedometers.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__emailWrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	&__labelEmail {
		@include font-montserrat($fz: 14px, $fw: 600);
		color: #c0c0c0;
	}

	&__email {
		@include font-hind($fz: 20px, $fw: 400);
	}

	&__logoWrapper {
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 30px 0;
		background-color: #051d1e;
	}

	&__logo {
		@include font-montserrat($fz: 16px, $fw: 800);
		color: #8bb298;
		text-align: center;
		text-transform: uppercase;
		text-wrap: wrap;

		p {
			max-width: 110px;
		}
	}
}

.footerLogo {
	margin-top: 80px;
}

@media (width <= 1200px) {
	.footer {
		&__circle {
			display: none;
		}
	}
}

@media (width <= 768px) {
	.footer {
		margin-top: 0;

		&__questions {
			padding: 40px 20px !important;
		}

		&__contacts {
			padding: 40px 20px 100px;
			background-image: url('../../assets/speedometers-320.png');
			background-position: -1px;
		}

		&__emailInput {
			max-width: 100%;
		}

		&__logoWrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 25px 0 70px;
		}

		&__buttons {
			max-width: 100%;
		}

		&__logo {
			max-width: 110px !important;
		}
	}

	.footerLogo {
		margin-top: 30px;
	}
}
