@import '/node_modules/normalize.css/normalize.css';
@import './fonts';
@import './variables';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	scroll-padding-top: 100px;
}

body {
	background-color: $color-background;
}

body.modalOpen {
	overflow: hidden;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button {
	margin: 0;
	padding: 0;
}

h1 {
	font-weight: 800;
	font-size: 80px;
	font-family: 'Montserrat', sans-serif;
}

h2 {
	font-weight: 800;
	font-size: 40px;
	font-family: 'Montserrat', sans-serif;
}

h3 {
	font-weight: 400;
	font-size: 30px;
	font-family: 'Hind', sans-serif;
}

fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

.slick-arrow::after,
.slick-arrow::before {
	display: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	background: #ffffff;
}

.slick-slider {
	max-width: 100%;
}
