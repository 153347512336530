@import 'src/styles/mixins';

.tag {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	border: 0;
	cursor: pointer;

	&__icon {
		width: 30px;
		stroke: #1a1919;
	}

	&__title {
		@include font-montserrat($fz: 14px, $fw: 300);
		padding: 5px 15px;
		text-transform: uppercase;
		background-color: #f7f7f7;
	}
}
