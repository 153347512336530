@import 'src/styles/mixins';

.propertiesBar {
	@include font-montserrat($fz: 16px, $fw: 600);
	display: flex;
	justify-content: space-between;
	gap: 10px;
	max-width: 325px;
	color: #252525;

	&__list {
		display: flex;
		gap: 1px;
	}

	&__button {
		width: 25px;
		height: 6px;
		background-color: #dbdbdb;
		border: 0;
		cursor: pointer;

		&--active {
			background-color: #14362f;
		}
	}
}

.filterProperties {
	width: 100%;

	&__list {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}

@media (width <= 480px) {
	.propertiesBar {
		flex-direction: column;
		max-width: 100%;
	}
}
