@import 'src/styles/mixins';

.heroCar {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 600px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background: rgb(0 0 0 / 70%);
		content: '';
	}

	&__content {
		z-index: 2;
		display: flex;
		flex-direction: column;
		gap: 35px;
		height: 100%;
		padding-bottom: 50px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		gap: 20px;
	}

	&__rating {
		width: 74px;
		height: 74px;
		font-size: clamp(1.25rem, 1.18rem + 0.36vw, 1.5rem);
	}

	&__buttonClose {
		margin-top: 30px;
	}

	&__titles {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: auto;
	}

	&__title {
		color: #ffffff;
		font-size: clamp(1.75rem, 1.36rem + 1.96vw, 3.13rem);
		text-transform: uppercase;
	}

	&__commentWrapper {
		align-self: flex-start;
		margin-bottom: auto;
	}
}

@media (width <= 768px) {
	.heroCar {
		&__content {
			padding: 0 20px;
		}
	}
}

@media (width <= 480px) {
	.heroCar {
		&__rating {
			width: 64px;
			height: 64px;
		}

		&__commentWrapper {
			align-self: unset;
		}
	}
}
