@import 'src/styles/mixins';

.starProgress {
	@include font-montserrat($fz: 14px, $fw: 700);
	display: flex;
	align-items: center;
	gap: 30px;
	width: 100%;
	color: #000000;
	background-color: transparent;
	border: 0;
	cursor: pointer;

	&__rating {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		width: 100%;
		max-width: 42px;
	}

	&__ratingNumber {
		width: 100%;
		max-width: 10px;
		text-align: center;
	}

	&__icon {
		fill: #f6d5a6;
		stroke: #f6d5a6;
	}

	&__progressWrapper {
		position: relative;
		width: 100%;
		max-width: 300px;
		height: 6px;
		background-color: #d8dcdd;
		border-radius: 3px;
	}

	&__track {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #14362f;
		border-radius: 3px 0 0 3px;

		&--active {
			background-color: orange;
		}

		&--full-border-radius {
			border-radius: 3px;
		}
	}
}

.filterStar {
	&__list {
		display: flex;
		flex-direction: column-reverse;
		gap: 15px;
	}
}

@media (width <= 768px) {
	.starProgress {
		&__progressWrapper {
			max-width: 100%;
		}
	}
}
