@import 'src/styles/mixins';

.comment {
	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: repeat(2, 1fr);
	align-items: end;
	padding: 60px 20px;
	column-gap: 125px;
	border-top: 1px solid #d3d4d6;

	&__date {
		@include font-montserrat($fz: 12px, $fw: 600);
		grid-column: 1 / 3;
		color: #000000;
		text-align: end;
	}

	&__info {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__name {
		@include font-montserrat($fz: 20px, $fw: 700);
		color: #1a1919;
	}

	&__data {
		@include font-montserrat($fz: 12px, $fw: 600);
		display: flex;
		flex-direction: column;
		gap: 5px;
		color: #a3a3a3;
	}

	&__text {
		@include font-hind($fz: clamp(0.88rem, 0.84rem + 0.18vw, 1rem), $fw: 400);
		color: #4f4f4f;
		line-height: 1.3;
	}

	&__properties {
		display: flex;
		justify-content: space-between;
		gap: 30px;
		width: 100%;
	}

	&__column {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}

@media (width <= 768px) {
	.comment {
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 20px;

		&__date {
			width: 100%;
		}

		&__properties {
			flex-direction: column;
			width: unset;
		}
	}
}
