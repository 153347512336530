.sliderCars {
	&__slider {
		margin-bottom: 50px;

		:global(.slick-list) {
			overflow: visible;
		}

		:global(.slick-slide) {
			opacity: 0;
			transition: all 0.5s ease;
		}

		:global(.slick-center + .slick-slide) {
			opacity: 0.5;
			filter: brightness(0.7);
		}

		:global(.slick-center) {
			opacity: 1;
			filter: brightness(1);
		}

		:global(.slick-prev) {
			left: 5px;
		}

		:global(.slick-next) {
			right: 5px;
		}

		:global(.slick-dots) {
			bottom: -40px;
			display: flex !important;
			justify-content: center;
			gap: 10px;
		}

		:global(.slick-dots li) {
			width: unset;
			height: unset;
		}

		:global(.slick-dots li button::before) {
			display: none;
		}

		:global(.slick-dots li button) {
			width: 10px;
			height: 10px;
			background-color: #dcdddd;
			border-radius: 50%;
			transition: all 0.2s ease;
		}

		:global(.slick-dots .slick-active button) {
			width: 40px;
			background-color: #14362f;
			border-radius: 5px;
		}
	}

	&__slide {
		border-radius: 10px;
	}

	&__image {
		width: clamp(14.38rem, 1.59rem + 64.29vw, 59.38rem);
		height: clamp(9.38rem, 2.41rem + 34.82vw, 33.8rem);
		object-position: center;
		object-fit: cover;
		border-radius: 10px;
	}
}
