@import 'src/styles/mixins';
@import 'src/styles/variables';

.bannerWrapper {
	display: flex;
	justify-content: center;
	padding: 60px 0;
	overflow: hidden;
	background-color: #8bb298;
}

.banner {
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;

	&__titles {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
		color: #ffffff;
	}

	&__title {
		z-index: 2;
		max-width: 500px;
		font-size: clamp(1.63rem, 1.5rem + 0.9vw, 2.3rem);
	}

	&__text {
		@include font-hind($fz: clamp(1rem, 0.86rem + 0.71vw, 1.5rem), $fw: 400);
		z-index: 2;
		max-width: 350px;
	}

	&__buttons {
		max-width: 180px;
	}

	&__images {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__image--background {
		position: absolute;
		top: -25%;
		left: -20%;
		z-index: 2;
		width: 600px;
		filter: blur(3px);
	}

	&__image--foreground {
		position: absolute;
		top: -15%;
		left: -5%;
		z-index: 3;
	}

	&__circle {
		position: absolute;
		top: -15%;
		left: calc(100% - 85%);
		width: 620px;
		height: 620px;
		background: $gradient-primary;
		border-radius: 50%;
	}
}

@media (width <= 1200px) {
	.banner {
		&__images {
			position: absolute;
		}

		&__image--background,
		&__image--foreground {
			display: none;
		}

		&__circle {
			top: -20%;
			left: calc(100% - 50%);
		}
	}
}

@media (width <= 768px) {
	.bannerWrapper {
		padding: 60px 20px;
	}
}

@media (width <= 480px) {
	.banner {
		&__buttons {
			max-width: 100%;
		}

		&__circle {
			top: -25%;
			left: calc(100% - 90%);
		}
	}
}
