@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
	display: flex;
	justify-content: center;
	margin-bottom: 15px;
	background: $gradient-primary;

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 145px;
		padding-top: 10px;
	}

	&__logo {
		@include font-montserrat($fz: 21px, $fw: 800);
		max-width: 140px;
		color: #8bb298;
		text-align: center;
		text-transform: uppercase;
	}
}

.nav {
	&__list {
		display: flex;
		gap: 25px;
		margin-top: 10px;
	}

	&__link {
		@include font-montserrat($fz: 22px, $fw: 500);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 170px;
		padding: 30px 40px;
		color: $color-text-secondary;
		border-radius: 5px 5px 0 0;
		opacity: 0.5;
		transition: all 0.3s ease;

		&:hover {
			color: #000000;
			background-color: $color-background;
			opacity: 1;
		}

		&--on {
			color: #000000;
			font-weight: 600;
			background-color: $color-background;
			opacity: 1;
		}
	}
}

.burger {
	display: flex;
	justify-content: center;
	align-items: center;

	&__button {
		width: 40px;
		height: 40px;
		background-color: #8bb298;
		border: 0;
		border-radius: 50%;
		cursor: pointer;
		stroke: #ffffff;
	}
}

@media (width <= 1200px) {
	.header {
		&__content {
			gap: 40px;
		}
	}
}

@media (width <= 960px) {
	.header {
		position: relative;
		margin-bottom: 0;

		&__content {
			gap: 10px;
			padding: 20px 40px 20px 20px;
		}
	}

	.nav {
		position: absolute;
		top: calc(100% - 5%);
		left: 0;
		z-index: 20;
		width: 100%;
		height: 100%;
		background-color: #ffffff;

		&__list {
			flex-direction: column;
			gap: 0;
			margin-top: 0;
			background: linear-gradient(180deg, #2a6258 0%, #0a2726 99.41%);
		}

		&__link {
			justify-content: start;
			width: 100%;
			padding-left: 40px;
			border-top: 1px solid #ffffff;
			border-radius: 0;
			opacity: 1;
		}
	}
}
