@import 'src/styles/mixins';

.faq {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;

	&__title {
		color: #383632;
		font-size: clamp(1.63rem, 1.4rem + 1.3vw, 2.5rem);
	}

	&__content {
		width: 100%;
		padding: 60px 125px 75px 75px;
		background-color: #ffffff;
		border-radius: 5px;
	}
}

@media (width <= 1200px) {
	.faq {
		padding: 0;

		&__content {
			padding: 0;
			padding-bottom: 60px;
		}
	}
}
