@import 'src/styles/mixins';

.modalComment {
	&__wrapper {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		display: flex;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding-bottom: 140px;
		overflow-y: auto;
		background-color: rgba($color: #000000, $alpha: 80%);
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 50px;
		height: max-content;
		margin-top: 140px;
		padding: 50px 100px;
		background-color: #ffffff;
		border-radius: 5px;
	}

	&__buttonClose {
		position: absolute;
		top: -70px;
		left: 100%;
	}

	&__title {
		color: #383632;
		font-size: clamp(1.75rem, 1.36rem + 1.96vw, 3.16rem);
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 50px;
	}

	&__profile {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 25px;
	}

	&__legend {
		@include font-montserrat(
			$fz: clamp(1rem, 0.96rem + 0.18vw, 1.13rem),
			$fw: 800
		);
		padding-bottom: 40px;
		color: #464646;
	}

	&__specifications {
		display: flex;
		flex-direction: column;
		gap: 35px;
	}

	&__dropdownWrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 25px;
	}

	&__numbersWrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 25px;
	}

	&__parameters {
		display: flex;
		justify-content: space-between;
		gap: 40px;
	}

	&__column {
		display: flex;
		flex-direction: column;
		gap: 45px;
		width: 100%;
	}

	&__captchaWrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}

	&__button {
		max-width: 170px;
	}
}

@media (width <= 1320px) {
	.modalComment {
		&__buttonClose {
			left: 90%;
		}
	}
}

@media (width <= 1200px) {
	.modalComment {
		&__content {
			border-radius: 0;
		}
	}
}

@media (width <= 768px) {
	.modalComment {
		&__content {
			padding: 30px 20px 70px;
		}

		&__buttonClose {
			left: 80%;
		}

		&__profile {
			flex-direction: column;
		}

		&__parameters {
			flex-direction: column;
		}

		&__captchaWrapper {
			align-items: center;
		}

		&__button {
			max-width: 100%;
		}
	}
}

@media (width <= 480px) {
	.modalComment {
		&__buttonClose {
			top: -60px;
		}
	}
}
