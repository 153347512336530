@import 'src/styles/mixins';
@import 'src/styles/variables';

.card {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 35px;
	width: 100%;
	max-width: 270px;
	height: 100%;
	padding-bottom: 30px;
	background-color: $color-primary;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.5s;

	&--no-full-width {
		max-width: 270px !important;
	}

	&:hover {
		box-shadow: 0 8px 12px 0 #0000002d;
	}

	&__rating {
		align-self: flex-start;
		width: 40px;
		height: 40px;
		margin-left: 25px;
	}

	&__image {
		width: 240px;
		height: 150px;
		object-fit: cover;
		object-position: center;
		pointer-events: none;
	}

	&__title {
		@include font-montserrat($fz: 20px, $fw: 500);
		min-height: 50px;
		padding: 0 10px;
		color: #000203;
		text-align: center;
		text-transform: uppercase;
	}

	&__stars {
		margin-top: auto;
	}

	&__link {
		@include font-montserrat($fz: 16px, $fw: 500);
		display: flex;
		align-items: center;
		gap: 20px;
		color: #000000;
		cursor: pointer;
		pointer-events: all;
	}
}

@media (width <= 1220px) {
	.card {
		max-width: 100%;
	}
}
