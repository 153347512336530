@import 'src/styles/variables';
@import 'src/styles/mixins';

.hero {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 150px;
	padding-top: 50px;

	&__image {
		position: absolute;
		top: 0;
		left: calc(100% - 60%);
		width: clamp(20rem, 9.6rem + 51.8vw, 56.3rem);
	}

	&__circle {
		position: absolute;
		top: 4%;
		left: calc(100% - 30%);
		z-index: -1;
		width: clamp(15.63rem, 13.48rem + 10.71vw, 23.13rem);
		height: clamp(15.63rem, 13.48rem + 10.71vw, 23.13rem);
		background: $gradient-primary;
		border-radius: 50%;
		object-fit: cover;
	}

	&__text {
		display: flex;
		flex-direction: column;
		gap: 50px;
	}

	&__titles {
		display: flex;
		flex-direction: column;
		gap: 35px;
	}

	&__heading {
		color: #2b6359;
		font-size: clamp(1.8rem, 0.8rem + 4.6vw, 5rem);
	}

	&__subtitle {
		@include font-hind(
			$fz: clamp(1.25rem, 1.07rem + 0.89vw, 1.88rem),
			$fw: 400
		);
		max-width: 330px;
		color: $color-text-primary;
	}

	&__buttons {
		display: flex;
		gap: 25px;
		max-width: 300px;
	}
}

@media (width <= 1200px) {
	.hero {
		&__circle {
			left: calc(100% - 35%);
		}
	}
}

@media (width <= 960px) {
	.hero {
		align-items: center;
		gap: 40px;
		margin-top: 15px;
		padding: 0 25px;

		&__image {
			position: unset;
			margin-top: 10px;
			margin-left: calc(100% - 90%);
		}

		&__circle {
			top: auto;
			left: auto;
		}

		&__text {
			gap: 20px;
			width: 100%;
		}

		&__titles {
			align-items: center;
			gap: 10px;
			text-align: center;
		}

		&__buttons {
			flex-direction: column;
			gap: 20px;
			max-width: 100%;
		}
	}
}
