@import 'src/styles/mixins';

.reviews {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;

	&__title {
		margin-bottom: 20px;
		color: #383632;
		font-size: clamp(1.63rem, 1.38rem + 1.25vw, 2.5rem);
	}

	&__list {
		&--border {
			border-bottom: 1px solid #d3d4d6;
		}
	}

	&__listTitle {
		@include font-montserrat($fz: 20, $fw: 500);
	}
}

@media (width <= 768px) {
	.reviews {
		gap: 35px;

		&__title {
			margin-bottom: 0;
			padding: 0 20px;
		}
	}
}
