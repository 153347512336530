@import 'src/styles/variables';

.charCar {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 65px 20px;
	background: $gradient-primary;

	&--overflow-items {
		padding-bottom: 25px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;

		&--overflow-items {
			gap: 45px;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 25px;

		&--overflow-items {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

@media (width <= 768px) {
	.charCar {
		padding: 45px 20px;

		&__content {
			align-items: stretch;
		}

		&__list {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
	}
}
